.actualContent_Transmission
{
    font-family: Arial,Helvetica,sans-serif;
    font-size: 62.5%;
}

.innerContent {
    /* color: #262626;
    float: left;
    font-size: 1.2em;
    padding: 0;
    width: 550px; */
    font-size: 1.2em;
}
.pagehead {
    color: #333333;
    display: block;
    font-size: 1.7em;
    font-weight: bold;
    margin-bottom: 10px;
}
#on_island {
    top: 60%;
    height: 86px;
    width: 40%;
    left: 25%;
}
.rightSide {
    border-top: 2px solid #333333;
    /* color: #333333;
    float: left;
    margin-left: 10px;
    width: 180px; */
}
.subHeadingText {
    display: block;
    font-size: 1.4em;
    font-weight: bold;
    margin: 0 0 0 15px;
    padding: 10px 0 13px;
}
.rightside_content_tran {
    border-top: 1px solid #CCCCCC;
    /* padding: 10px 0 13px; */
}
div.rightside_content_tran p {
    color: #666666;
    font-size: 1.2em;
    margin: 0 0 0 15px;
}
div.actualContent_Transmission a {
    font-weight: bold;
}
.leftSide {
    border-top: 1px solid #fff;
}
#sodeman {
    top: 60%;
    height: 135px;
    width: 40%;
    left: 25%;
}

ul > li {
    list-style-type: circle !important;
    padding-left: 1em;
}

a
{
    font-weight: bold;
    color: #006CB5;
    text-decoration: none;
}
.dot
{
    margin-top:  -3%;
}

.Section
{
   font-weight: bolder;
}
