
#EmfBody {
    font-size: 62.5%;
    font-family: Arial, Helvetica, sans-serif;
    color: #000;
    background-color: #e5e5e5;
    margin: 0 0 35px 0;
}

div#wrapper {
    background-color: #fff;
    /*width: 980px;*/
    padding: 0 0 0 20px;
}

#leftnav {
    /* width: 180px;*/
    float: left;
    margin-right: 20px;
    font-size: 1.2em;
    font-weight: bold;
}

div#leftnav span.leftnav {
    display: block;
   
}


.clearfix {
    display: block;
  
}

div#DivEmfBody a, a:link, a:visited {
    color: #006cb5;
    text-decoration: none;
    
}


div#DivEmfBody a, a:hover, a:focus {
    color: #e78c07;
    text-decoration: none;
}

div#leftnav span.leftnav a, div#leftnav span.leftnav b {
    display: block;
    padding: 0 10px 12px 10px;
}

div#leftnav span.leftnav .current {
    color: #e78c07;
}

b {
    font-weight: bold;
}

div {
    display: block;
}

    div#actual_content_env {
        padding: 0 0 30px 0;
    }

        div#actual_content_env div.innerContent b {
            color: #333;
            font-size: 1.4em;
            font-weight: bold;
            display: block;
        }

        div#actual_content_env div.innerContent {
            font-size: 12px;
            color: #262626;
            /*   width: 550px;*/
            padding: 0 20px 0 0;
            float: left;
        }

    div.innerContent .pagehead {
        font-size: 1.7em;
        color: #333333;
        margin-bottom: 10px;
        font-weight: bold;
        display: block;
    }

    div#actual_content_env div.innerContent span.smallfont {
        color: #999;
        font-size: 10px;
    }

    div#actual_content_env a {
        font-weight: bold;
    }

    div.right_side {
        float: left;
        /* width: 180px;*/
        color: #333;
        border-top: 2px solid #333333;
        margin-left: 10px;
    }

        div.right_side .subHeadingText {
            display: block;
            padding: 10px 0 13px 0;
            margin: 0 0 0 15px;
            font-size: 1.4em;
            font-weight: bold;
        }

    div.rightside_content {
        border-top: 1px solid #cccccc;
        padding: 10px 0 13px 0;
    }

        div.rightside_content p {
            font-size: 110%;
            color: #666;
            padding-bottom: 10px;
            line-height: 14px;
            
            }

    div#actual_content_env div.innerContent ul {
        padding: 8px 0 15px 28px;
        margin: 0;
    }

    div#actual_content_env ul li {
        list-style-type: disc;
    }


    div#actual_content_index ul {
        padding-left: 1px;
    }

    div#actual_content_index ul li {
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }
    div#head.subhead {
        color: #006CB5;
        font-size: 1.4em;
        font-weight: bold;
        text-decoration: none;
    }
    #strategy_img{
    position: relative;
    height: 87px;
    width: 220px;
    left: 92%;
}

.Catv_PageHead {
    COLOR: #00345B;
    FONT-FAMILY: Arial, Helvetica, sans-serif;
    FONT-SIZE: 17px;
   /* font-weight: bold;*/
}

.divrowpadding {
    padding-top: 3px;
    padding-bottom: 3px;
}
