
html, body {
	margin: 0;
	padding: 0;
	border: 0;
	font-weight: inherit;
	font-style: inherit;
	font-size: 100%;
	font-family: 'inherit';
	vertical-align: baseline;
}

.column2 tbody{font-size:12px;}
.column2 tbody tbody{font-size:1em;}
.column2 tbody a{font-size:1em;}
.column2 tbody a.grouptitle,.column2 tbody .grouptitle{font-size:1.33em;font-weight:bold;padding-bottom: 15px;padding-top: 15px;}
.column2 tbody a.header{font-weight:bold;}
.column2 tbody h3{font-size:1.33em;}
.column3 tbody td td{padding:0;}
.flyout_pageTitleInfo a{font-size:12px;}
.genericContent p{font-size:12px;}
.genericContent table tr td{font-size:12px;}
.column2 .genericContent table tr td{font-size:1em;}
table tr td div.pagetitle{font-size:30px;}
.genericContent table tr td p, .genericContent table tr td td{font-size:1em;}
.threecoloumlayout .column2 td{ padding:0!important;}


/* Start Content Page Titles */
div.pagetitle {
    border-bottom: 1px solid #262626;
    border-top: 1px solid #262626;
    padding:10px 0 10px 0;
    color: #333333;
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 15px;
    padding-bottom: 12px;
    padding-left: 0;
    padding-right: 8px;
}

.pageTitle h1 {
	color: #333333;
	padding: 12px 8px;
}
.pageTitleInfo {
	color:#666;
	font-size:1.5em;
	letter-spacing:.3px;
	line-height:1.25em;
}
.pageSubTitle h4 {
	padding: 10px 8px;
}
.pageSubText {
	padding: 0px 0px 10px 10px;
}
.headerPageLink {
	padding:25px 0px 0px 10px;
}

.threecoloumlayout .column2 table ul li{
	list-style-type:disc;
}
/* End Content Page Titles */

/* Start Three Coloum Layout Section */

.threecoloumlayout {
	display: block;
	background-color: #ffffff;	
}
.threecoloumlayout .column-1 {
	display: inline;
	float: left;
	margin-right: 20px;
	margin-left:20px!important;
	width: 220px;
	border:1px solid #ffffff;
}
.threecoloumlayout .column2 {
	display: inline;
	float: left;
	margin-left: 20px;
	margin-right: 30px;
	width: 500px;
}
.threecoloumlayout .column3 {
	display: inline;
	margin-right: 15px;
	margin-right: 0;
	padding: 0px 5px 20px 0px;
}

/* End Three Coloum Layout Section */

/* Start Input Form Section */
.formHeader {
	margin-bottom: 32px;
}
.formHeaderTitle {
	position: relative;
	background-color: #f2f2f2;
}
.formHeaderTitle .title {
	border-top: solid 1px #cccccc;
	border-bottom: solid 1px #cccccc;
	padding: 12px 0 20px 16px;
}
.formHeaderTitle .subtitle {
	border-top: solid 1px #cccccc;
	padding: 12px 0 15px 16px;
}
.formHeaderTitle .title h3 {
	margin: 0;
}
.formHeaderTitle .title h4 {
	margin: 0;
	color:#333333;
}
.formHeaderTitle .title a {
	font-weight: bold;
}
.formHeaderTitle .explanation {
	padding: 8px 12px 12px 16px;
	color: #666666;
}
.formHeaderTitle .explanationsugform {
	padding: 8px 12px 12px 10px;
}
.formHeaderTitle .explanation p {
	margin: 0;
}
.formInput {
	position: relative;
	background-color: #f2f2f2;
	border-bottom: solid 1px #cccccc;
}
.formInput .fields {
	display: inline-block;
}
.formInput .rows {
	display: inline-block;
	position: relative;
	padding-top: 3px;
	padding-bottom: 5px;
	margin: 0 0 5px;
}
.formInput .rows:after {
	content: " ";
	display: block;
	height: 0;
	clear: both;
	overflow: hidden;
	visibility: hidden;
}
.formInput .rows {
	display: block;
}
.formInput .rows .col1 {
	display: inline;
	float: left;
	margin-right: 20px;
	width: 160px;
}
.formInput.rows .col1 label {
	display: block;
	width: 80%;
}
.formInput .rows .col1 .inner {
	padding-left: 16px;
}
.formInput .rows .col2 {
	display: inline;
	float: left;
	width: 340px;
	margin-right: 0;
}
.formInput .rows .col2 .radio span {
	margin-right: 25px;
}
.formInput .rows .col1 input, .formInput .rows .col2 input {
	padding: 1px;
	margin: 0;
	font-weight:normal;
	width:200px;
}
.formInput .rows .col1 input[type=radio], .formInput .rows .col2 input[type=radio]
{
	width:15px;
}

.formInput label {
	font-weight:bold;
}
.formInputHelp {
	margin:3px 0px 0px 0px;
}
.formInputTwoRow .rows1 {
	display: inline-block;
	position: relative;
	padding: 0px 0px 10px 20px;
	margin: 0px 0px 10px 0px;
	width:240px;
	border-right:solid 1px #cccccc;
	float:left;
}
.formInputTwoRow .rows2 {
	display: inline-block;
	position: relative;
	padding: 0px 0px 10px 15px;
	margin: 0 0 10px 0px;
	width:230px;
	float:left;
}
 .formInputTwoRow .rows1 input[type=radio],, .formInputTwoRow .rows2 input[type=radio] {
 margin-top:-5px;
}
.subformborder {
	border-bottom: solid 1px #f2f2f2;
}
.telephoneInput {
	width:60px;
}
.formInputTwoRowHeight100 {
	height:150px;
}
.formInputTwoRowSubHeader {
	padding:10px 0px 20px 20px;
}

.forminputwidth	{
	width:150px;
}
/* End Input Form Section */
table {
	margin: 0;
	padding: 0;
	border: 0;
	font-weight: inherit;
	font-style: inherit;
	font-family: inherit;
	vertical-align: baseline;
	border-collapse: separate;
	border-spacing: 0;
	vertical-align: middle;
}
a img {
	border: none;
}
h1 {
	font-weight: bold;
	color: #333333;
	margin-bottom: 0px;
	font-size: 3em;
	line-height: 3em;
}
h1 img {
	margin: 0;
}
h2 {
	font-weight: bold;
	color: #333333;
	margin-bottom: 0px;
	font-size: 1.6em;
}
h3 {
	font-weight: bold;
	color: #333333;
	margin-bottom: 0px;
	font-size: 1.6em;
	line-height: 1;
}
h4 {
	font-weight: bold;
	color: #333333;
	margin-bottom: 0px;
	font-size: 1.6em;
	line-height: 1.25;
}
.typetitle{
	margin-top:2px;
	margin-bottom:10px;
}
.typetitle, h5 {
	font-weight: bold;
	color: #333333;
	margin-bottom: 0px;
	font-size: 16px;
}
h6 {
	font-weight: bold;
	color: #333333;
	margin-bottom: 0px;
	font-size: 0.8em;
	margin-bottom: 1em;
	font-weight: bold;
}
h2 img, h3 img, h4 img, h5 img, h6 img {
	margin: 0;
}
p {
	margin: 0;	
}
p img.left {
	display: inline;
	float: left;
	margin: 1.5em 1.5em 1.5em 0;
	padding: 0;
}
p img.right {
	display: inline;
	float: right;
	margin: 1.5em 0 1.5em 1.5em;
	padding: 0;
}

blockquote {
	margin: 1.5em;
	color: #666;
	font-style: italic;
}
strong {
	font-weight: bold;
}
em {
	font-style: italic;
}
dfn {
	font-style: italic;
	font-weight: bold;
}
sup, sub {
	line-height: 0;
}
abbr, acronym {
	border-bottom: 1px dotted #666;
}
address {
	margin: 0 0 1.5em;
	font-style: italic;
}
del {
	color: #666;
}
pre {
	margin: 1.5em 0;
	white-space: pre;
}
pre, code, tt {
	font: 1em 'andale mono', monospace;
	line-height: 1.5;
}
li ul, li ol {
	margin: 0 1.5em;
}
ul {
	margin: 0 1.5em 1.5em 1.5em;
	list-style-type: disc;
}
ul li {
	list-style-type: none;
	margin-left: 0px;
}
ol {
	margin: 0 1.5em 1.5em 1.5em;
	list-style-type: decimal;
}
dl {
	margin: 0 0 1.5em 0;
}
dt {
	font-weight: bold;
}
dd {
	margin-left: 1.5em;
}
table {
	margin-bottom: 0;
	width: 100%;
}
th {
	font-weight: bold;
}
thead th {
	background: white;
}
th, td, caption {
	padding: 2px ;
}
tr.even td {
	background: black;
}
tfoot {
	font-style: italic;
}
caption {
	background: #eee;
}
select {
	font-size: 1em;
	font-family: Arial;
}
input[type='text'], input[type='textarea'] {
 font-size: 1em;
 font-family: Arial, sans-serif;
}
label {
	color: #333333;
}
label.noted {
	margin-bottom: 0;
}
legend {
	font-weight: bold;
	font-size: 1.2em;
}
input.text, input.title, input[type=text], input[type=password]{
	margin: 0;
	background-color: #fff;
	padding: 3px 2px 4px 2px;
}
input.title {
	font-size: 1.5em;
}
input[type=radio], input.radio {
	position: relative;
	top: 0.2em;
}
textarea {
	margin: 0;
	padding: 5px;
}
select {
	margin: 0;
}
fieldset {
	border:none;
}
input.text, input.title, select {
	border: 1px solid #bbbbbb;
}
input.text:focus, input.title:focus, select:focus {
	border: 1px solid #666;
}
input.text, input.title {
	width: 300px;
}

#pageContainer {
	background:#fff;
	margin:0 auto;
	width:1020px;
}
#pageContainer #root_footer {
	height: 413px;
}


/*//////////////////////////// Start Screen Content ///////////////////////////////////////////////*/

body {	
	color: #595858;
	background-color: #e5e5e5;
	font-family:Arial, sans-serif !important;
}
#content-top, #content {
	text-align: left;
}
#header-container .wrapper {
	background: #ffffff;
	margin: 0 auto;
	text-align: left;
}
.grouptitle{
	font-size:2em;
}

a.headerSM{
    font-size:1.67em!important;
}
a.smallheaderSM {
	font-size:1.25em!important;
	font-weight:bold;
}
a.smalltextSM {
	line-height:1.5em;
}

#validRecipientEmail, #validYourEmail
{
margin-top: 4px;
margin-left: 9px;
position: absolute;
width: 16px;
height: 16px;
}

.aligningsize
{
width:480px;    
}
    
    
    @media screen and (-webkit-min-device-pixel-ratio:0) { .aligningsize {
                             width:240px;
}}
/*//////////////////////////// End Screen Content ////////////////////////////////////////////////*/
/*//////////////////////////// Start NG Logo ////////////////////////////////////////////////////*/
.nglogo a {
	display: block;
	overflow: hidden;
	height: 55px;
	width: 145px;
	margin:11px 0 22px 10px;
}

.nglogoopt {
	display: block;
	overflow: hidden;
	height: 55px;
	width: 175px;
	margin:11px 0 22px 10px;
}
.nglogofooter  a {
	overflow: hidden;
	height: 55px;
	width: auto;
}

/*//////////////////////////// End NG Logo /////////////////////////////////////////////////////*/

/*//////////////////////////// Start Header Section ///////////////////////////////////////////*/

#header-container {
	width: 100%;
	position: relative;
	text-align: center;
	z-index: 1001;
}
#header-container .wrapper {
	width: 940px;
	padding-right: 40px;
	padding-left: 40px;
	margin: 0 auto;
	text-align: left;
	height: 149px;
}
#header {
	display: inline;
	float: left;
	margin-right: 20px;
	width: 940px;
	height: 80px;
	padding-top: 15px;
}
#header .column-1 {
	display: inline;
	float: left;
	margin-right: 0px;
	width: 220px;
}
#header .column2 {
	display: inline;
	float: left;
	margin-right: 20px;
	width: 700px;
	margin-right: 0;
}
#header .column2 .row-1, #header .column2 .row-2 {
	overflow: hidden;
	display: inline-block;
}
#header .column2 .row-1, #header .column2 .row-2 {
	display: block;
}
#header .column2 .cell-1 {
	display: inline;
	float: left;
	margin-right: 20px;
	width: 0px;
}
#header .column2 .cell-2 {
	display: inline;
	float: left;
	margin-right: 20px;
	width: 700px;
	margin-right: 0;
	font-weight: bold;
}
#header .column2 .row-1 {
	width: 700px;
	margin-left:20px;
}
#header .column2 .row-1 ul {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	overflow: hidden;
	display: inline-block;
}
#header .column2 .row-1 ul {
	display: block;
}
#header .column2 .row-1 ul li {
	list-style-type: none;
	margin-left: 0px;
	white-space: nowrap;
	display: inline;
	float: left;
	padding-left: 10px;
	padding-right: 10px;
}
#header .column2 .row-1 ul li.first {
	padding-left: 0px;
}
#header .column2 .row-1 ul li.last {
	padding-right: 0px;
	border-right: none;
	margin-top: -2px;
}
#header .column2 .row-1 ul li {
	border-right: solid 1px #eee;
	border-left: solid 1px #eee;
	display: inline;
	float: right;	
}
#header .column2 .row-1 ul .location {
	border: none;
	margin:0px 0px 0px 0px;
	margin: -2px 0px 0px 0px;
	border-right: solid 1px #eee;
}
#header .column2 .row-1 ul .locationNoRtBor {
	border: none;
	margin:0px 0px 0px 0px;
	margin: -2px 0px 0px 0px;
}
#header .column2 .row-2 .cell-1, #header .column2 .row-2 .cell-2 {
	margin-top: 15px;
}
#header .column2 .row-2 .cell-1 {
	display: inline;
	float: left;
	margin-right: 20px;
	width: 400px;
}
#header .column2 .row-2 .cell-2#search {
	display: inline;
	float: left;
	margin-right: 20px;
	width: 280px;
	text-align: right;
	margin-right: 0;
}
.search-box div {
	padding: 0 0px 0 7px;
}
.search-box input.text {
	width: 223px;
	background-color: #EEEEEE;
	border: none;
	height:20px;
	padding-left:5px;
}
.search-box input.text:focus {
	border: none;
}
.search-box input {
	margin: 0;
}
.search-box .searchbox {
	background-color: #cccccc ;
}
.search-box .searchsubmit {
	padding: 0 0 0 8px;	
}
#header .searchsubmit {
	width: 30px;
}

/*//////////////////////////// End Header Section ///////////////////////////////////////////*/

.grayBorder{
	border:solid 1px #cccccc!important;
	border-spacing: 0!important;
	cellpadding:0!important;
	cellspacing:0!important;
	border-collapse:collapse;
	}
.tablemain {
	
	font-size: 12px !important;
	font-style: normal;
	font-weight: 400;
	line-height :15px !important;
	text-align: left;
	text-indent: 0px;
	vertical-align: top;
	margin: 0px;
	padding: 0px;
	color:rgb(33, 37, 41); 
}

.stepsbg {
	background-color:#ffffff;
}
.sectionwidth_min {
	width:690px;
	float:left;
}
.sectionbrake {
	border-top:solid 1px #000000;
}
.modheader {
	padding: 10px 10px 10px 5px;
	font-size: 13px;
	font-weight: bold;
	color: #333;
	border-top: 1px solid #262626;
	border-bottom: 1px solid #CCCCCC;
	/* margin: 0 -10px 10px -5px; */
	width:150px;
}
.threecoloumlayout .column3 {
	display: inline;
	float: left;
	margin-right: 15px;
	width: 165px;
	margin-right: 0;
	padding: 0px 5px 5px 0px;
}
.modifyGroup .column3	{
	display: inline;
	float: left;
	width: 60px;
	padding-top: 15px;
}
.modifyGroup .column3	{
	display: inline;
	float: left;
	width: 60px;
	padding-top: 15px;
}
.featuresection .column3 {
	display: inline;
	float: left;
	margin-right: 20px;
	width: 320px;
	margin-right: 0;
}
.promosection .column3 {
	display: inline;
	float: left;
	margin-right: 20px;
	width: 340px;
	margin-right: 0;
}
#footer .inner .row-1 .column2, #footer .inner .row-1 .column3, #footer .inner .row-1 .column-4 {
	margin-right: 0;
	padding-right: 40px;
	background-color: #666666;
}
#footer .inner .row-1 .column2 p, #footer .inner .row-1 .column3 p, #footer .inner .row-1 .column-4 p {
	color:#c4c4c4;
	margin: 0 0 1.3em;
	font-size:1.2em;
}
#footer .inner .row-1 .column2 span, #footer .inner .row-1 .column3 span, #footer .inner .row-1 .column-4 span {
	color:#ffffff;
}
#footer .inner .row-1 .column2 .wrapper, #footer .inner .row-1 .column3 .wrapper, #footer .inner .row-1 .column-4 .wrapper {
	position: relative;
	left: 20px;
}
#footer .inner .row-1 .column2 a, #footer .inner .row-1 .column3 a, #footer .inner .row-1 .column-4 a {
	text-decoration: none;
	color:#ffffff;
}
#footer .inner .row-1 .column2 a:hover, #footer .inner .row-1 .column3 a:hover, #footer .inner .row-1 .column-4 a:hover {
	text-decoration: underline;
}
.column3 a.contextuallink {
	padding-left:5px;
}

.column3 table a.contextuallink {
	margin-left:0px;
	padding-left:0px;
}
.column3 a{line-height:8px;}
.column3 tbody td td{padding:0;}
#nav #flyout_container #flyout .subnav .inner .column2 a, #nav #flyout_container #flyout .subnav .inner .column3 a {
	padding:5px 0px 10px 0px;
	font-weight:bold;
	font-size:1.1em;		
}
#nav #flyout_container #flyout .subnav .inner .column3 {
	margin-right: 0;
	width:270px;
}
#nav #flyout_container #flyout .subnav .inner .column3 ul li {
	padding:8px 0px 8px 0px;
	border-bottom: solid 1px #ccc;
	margin-left:-16px;
	width:230px;
}
#nav #flyout_container #flyout .subnav .inner .column3 p {
	padding: 0px 0px 0px 0px;
	color:#6d6d6d;
	width:250px;
	font-size:1.2em;
}
#nav #flyout_container #flyout .subnav .inner .column3 p a {
	border-bottom: none;
}
.contextuallinkcontact
{
	padding-right: 5px;
	padding-left: 0px;
	font-size: 12px;
}
.contextuallinkcontact_a{
	font-size:12px;
	text-decoration:none;
}
.contextuallink
{
	padding-right: 5px;
	padding-left: 0px;
	font-size: 12px;
}
.grouptitle{
	font-size:25px;
}

.column2 tbody a.grouptitle, .column2 tbody .grouptitle
{
	font-size:18px;
	font-weight:bold;
	padding-bottom: 15px;
	padding-top: 15px;
}
.mainlink_a{
	font-size:18px;
	text-decoration:none;
}
