.clearfix {
    display:block;
    }
    .clearfix:after {
    clear:both;
    content:".";
    display:block;
    height:0;
    visibility:hidden;
    }
    .divpagehead {
        color: #333333;
        display: block;
        font-size: 1.7em;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .divpageheadblue {
        color: #00345B;
        display: block;
        font-size: 17px;
        font-weight: bold;
        FONT-FAMILY: Arial, Helvetica, sans-serif;
    }
    .innerContent {
        color: #262626;
        float: left;
        font-size: 12px;
        padding: 0;
        width: 550px;
    }
    .div_right_side {
        border-top: 2px solid #333333;
        color: #333333;
        float: left;
        margin-left: 10px;
        margin-top: 10px;
        width: 180px;
    }
    .div_subHeadingText {
        display: block;
        font-size: 14px;
        font-weight: bold;
        margin: 0 0 0 15px;
        padding: 10px 0 13px;
    }
    .div_rightside_content {
        border-top: 1px solid #CCCCCC;
        padding: 10px 0 13px;
    }
    .rightside_content_p {
        color: #666666;
        font-size: 14px;
        margin: 0 0 0 15px;
    }
    a.subhead:link {
        color: #006CB5;
        font-size: 18px;
        font-weight: bold;
        text-decoration: none;
    }
    a.subhead:visited {
        color:#006CB5;
        font-size:18px;
        font-weight:bold;
        text-decoration:none;
        }
        a.subhead:hover {
        color:#E78C07;
        font-size:18px;
        font-weight:bold;
        text-decoration:none;
        }
.insurance_link
{
font-weight: bold;
color: #006CB5;
text-decoration: none;
font-size:12px;
}
.transmissionleftnav {
float:left;
font-size:15px;
font-weight:bold;
margin-right:20px;
margin-top:15px;
width:180px;
}
.LeftSideBox {
float:left;
}

.div_innerContent {
    color: #262626;
    float: left;
    font-size: 12px;   
    padding: 0;
    width: 550px;
}      
.textindent {
    margin-left:1px;
    margin-right:1px;
}
.nyes {
    position: relative;
    top: 60%;
    height: 26px;
    width: 40%;
    left: 30%;
}   
.span_smallfont {
    color:#999999;
    font-size:10px;
}
.divright_side {
    border-top:2px solid #333333;
    color:#333333;
    float:left;
    margin-left:10px;
    width:180px;
}
.divsubHeadingText {
    display:block;
    font-size:18px;
    font-weight:bold;
    margin:0 0 0 15px;
    padding:10px 0 13px;
}
.divrightside_content {
    border-top:1px solid #CCCCCC;
    padding:10px 0 13px;
}
.rightside_content_p {
color:#666666;
font-size:12px;
margin:0 0 10px 15px;
}