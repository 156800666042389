/*
Serviter map header footer CSS

.body_serviceterr_map {
	font-size: 62.5%;
	font-family: Arial, Helvetica, sans-serif;
    float: left;
    padding-left: 30px; 
	background-color: #fff;
}

.NGLogo {
    margin-top: 30px;
}

.clearfix {
	display: block;
	margin: 0;
	padding: 0;
}

div#BasicHeader div#utilityNav ul li.statename {
	float:left;
	padding: 8px 5px 6px 0;
}

div#BasicHeader div#utilityNav ul li.statename a {
	color:#78a22f;
	font-size:1.4em;
	padding-left:5px;
}

div#BasicHeader div#utilityNav 
{
	border-bottom: 5px solid #e5e5e5;
}

div#BasicHeader div#mainNavigation ul {
	list-style-type: none;
	float: right;
	
}
div#BasicHeader div#utilityNav ul li,
div#BasicHeader div#mainNavigation ul li {
	list-style-position: outside;
	float: left;
}
div#BasicHeader div#utilityNav ul li {
	padding: 8px 34px 8px 0;
}
div#BasicHeader div#utilityNav ul li.last {
	padding: 8px 30px 8px 0;
}
div#BasicHeader div#utilityNav ul li a {
	font-weight: bold;
	font-size: 1.1em;
}
div#BasicHeader div#utilityNav ul li a:hover,
div#BasicHeader div#utilityNav ul li a:active {
	color: #e78c07;
}
div#subNavigationMenus ul {
    background-color:#E5E5E5;
}
div#subNavigationMenus ul li {
	list-style-position: outside;
	float: left;
	padding: 0 0px 0 0;
}
div#subNavigationMenus ul li.last {
	padding: 0;
}
div#subNavigationMenus ul li a {
	padding: 10px 15px 8px 15px;
	display: block;
	font-weight: bold;
	font-size: 1.2em;
}
div#subNavigationMenus ul.tier1 li a:link,
div#subNavigationMenus ul.tier1 li a:visited {
	background-color: #e5e5e5;
	color: #006cb5;
}
div#subNavigationMenus ul.tier1 li.current a:link,
div#subNavigationMenus ul.tier1 li.current a:visited,
div#subNavigationMenus ul.tier1 li a:hover,
div#subNavigationMenus ul.tier1 li a:active {
	background-color: #666;
	color: #fff;
}
div#subNavigationMenus ul.tier2 {
	background-color: #666;
}
div#subNavigationMenus ul.tier2 li a {
	width: auto;
	padding: 10px 0;
	margin: 0 33px 0 10px;
}
div#subNavigationMenus ul.tier2 li a:link,
div#subNavigationMenus ul.tier2 li a:visited {
	color: #fff;
}
div#subNavigationMenus ul.tier2 li.current a:link,
div#subNavigationMenus ul.tier2 li.current a:visited,
div#subNavigationMenus ul.tier2 li a:hover,
div#subNavigationMenus ul.tier2 li a:active {
	color: #e78c07;
}
div.mainTopBanner {
	border-top: 5px solid #333333;
	padding:0 0 10px 0;
}

div#footer_holder {
	padding: 17px 0 0 0;
	border-top: 15px solid #e5e5e5;
}

div#footer_holder ul {
	list-style-type: none;
}
div#footer_holder ul.listLinks1  li.copyRight {
	font-weight:normal;
}
div#footer_holder ul li {
	list-style-position: outside;
}
div#footer_holder ul.listLinks1 {
	width: 939px;
	padding: 0 30px 15px 11px;
}
div#footer_holder ul.listLinks1 li {
	font-size: 1.1em;
	font-weight: bold;
	color: #666;
	float: left;
}
div#footer_holder ul.listLinks1 li.privacyPolicy {
	float: right;
}
#Niagram_leftnav {
	width:180px;
	float:left;	
	margin-right:20px;	
	font-size: 1.2em;
	font-weight: bold;
}
div.LeftSideBox {
	float:left;
}
div#Niagram_leftnav span.Niagram_leftnav {
	display: block;
}
div#Niagram_leftnav span.Niagram_leftnav a,
div#Niagram_leftnav span.Niagram_leftnav b {
	display: block;
	padding: 0 10px 12px 10px;
}
.no-decoration a {
	text-decoration: none;
  }
div#Niagram_leftnav span.Niagram_leftnav .current {
	color: #e78c07;
}
div#Niagram_leftnav span.Niagram_leftnav .inner {
	padding: 0 10px 12px 40px; 
}

div#Niagram_leftnav span.Niagram_leftnav .innerinner {
	padding: 0 10px 12px 60px; 
}

div#Niagram_leftnav span.Niagram_leftnav .inner.first {
	background: transparent url('../assets/images/reskin/bak_bg_faux_dotted_border.gif') repeat-x left top;
	padding: 12px 10px 12px 40px;
	border-top: 1px solid #c1c1c1;
}
div#Niagram_leftnav span.Niagram_leftnav .inner.last {
	border-bottom: 1px solid #c1c1c1;
	margin: 0 0 12px 0;
}
div.innerContent .pagehead {
    font-size: 1.7em;
    color: #333333;
    font-weight: bold;
}
.Niagram_tablefont {
    font-size: 1.1em;
    border: 1px solid #ccc;
}
.Backbutton {
    border: #A9C7EC 1px solid;
    background: #ffffff;
    color: #006cb5;
    font-weight: bold;
}
.Niagram_table img{
	display:block;
} */


/* ServiceTerr content area css */

.st_innerContent
{
font-size: 12px;
    /* color: #262626;
	width: 785px;
    padding: 0;
    float: right; */
}
.st_pagehead {
	font-family: Arial, Helvetica, sans-serif;
    font-size: 1.7em;
    color: #333333;
    margin-bottom: 10px;
    font-weight: bold;
    display: block;
}
.Niagram_table th, td, caption {
    padding: 0px !important;
	color: #666;
}
.bluefont {
    color: #006cb5;
    font-weight: bold;
	font-size: 75%;
}
.bluefont a {
    color: #006cb5 !important;
    text-decoration: none !important;
    font-size: 12px !important;
    font-weight: normal !important;
}
.bluefont a:hover {
	color: #ffA500 !important; 
  }
.Backbutton {
    border: #A9C7EC 1px solid;
    background: #ffffff;
    color: #006cb5;
    font-weight: bold;
	line-height: normal;
	font-size: small;
}
