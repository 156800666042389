/* GasLeakMap css */
@charset "utf-8";
.button_gasleak{
    border: 1px solid #0063be;
    background-color: #0063be;
    padding: 0 5px 0 5px;
    font-weight: bold;
    color: white;
}
.header
{
    color: #595858;
    font-size: 14px;
    background-color: #FFFFFF;
    font-family: Arial, sans-serif;
    padding-left: 10px;
    /* padding-right: 180px;
    width: 80%; */
}
.GasLeak_h3 {
    font-weight: bold;
    color: #333333;
    margin-bottom: 0px;
    font-size: 1.4em;
    line-height: 1;
}
.center_content
{
border-color: #e4e4e4;
border-style: solid;
border-width: 1px ;
padding: 5px;
}
.body
{
    background-color: #FFFFFF;
}
.floatLeft {
    float: left;
}
.textbox_gasleak
{
margin: 0;
background-color: #fff;
}

.paddingLeft10px {
    padding-left: 10px;
}
.error
{
    background: url(../assets/images/redExclamationPoint.gif) no-repeat 8px 18px;
    border-top: solid 1px #cc0001;
    color: #cc0001;
    padding: 16px 36px;
}
.hidden {
    display: none;
  }
.error h4
{
    color: #cc0001;
    line-height: 1em;
    font-size: 1.2em;
    margin-left: 15px;
}
.error ul
{
    line-height: 1em;
    margin-left: 1em;
    margin-bottom: 0;
}
.error ul li
{
    list-style: disc;
    padding-top: 6px;
    font-weight: bold;
}
.error div
{
    line-height: 1em;
    margin: 0 0 2px 0;
}
.error .noramBlacktext
{
    color: #000000;
}
.error span
{
    margin-left: 15px; 
}
.whitespacer {
    height: 10px;
}

