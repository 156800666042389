.pagehead {
	color: #00345B ;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 17px;
	font-weight: bold;
}
.body {
    color: #000000 !important;
    font-family: Verdana, sans-serif;
    font-size: 11px;
    margin: 0px;
    }
.spanBold{
    font-weight: bold;
}

.application {
    color: #00345B !important; 
    font-size:12px; 
    font-family: Verdana, sans-serif; 
    text-decoration: underline;
    font-weight: normal;
}
TD {
    color: #000000;
    font-family: Verdana, sans-serif;
    font-size: 11px;
}
.sectionhead {
    color: #00345B;
    font-family: Verdana, sans-serif;
    font-size: 13px;
    font-weight: bold;
}

.Backbutton {
    border: #A9C7EC 1px solid;
    background: #ffffff;
    color: #006cb5;
    font-weight: bold;
	line-height: normal;
	font-size: small;
}


.Title
{
	font-weight:  bolder;
	font-size: small;
}

.sectionhead1
{
	COLOR: #00345B;
    FONT-FAMILY: Arial, Helvetica, sans-serif;
    FONT-SIZE: 17px;
    font-weight: bold;
}

.ContactDetails
{
	COLOR: #000000;
    FONT-FAMILY: Verdana, sans-serif;
    FONT-SIZE: 11px;
}
div#DivEmfBody a, a:link, a:visited {
    color: #000;
    text-decoration: underline;
}
.sectionhead2 {
    color: #010a11;
    font-family: Verdana, sans-serif;
    font-size: 11px;

}

.line {
    color: #000;
    font-size: 13px;
    
}
.content {
    color: #000;
   }

   tbody, td, tfoot, th, thead, tr {
    border-color: inherit;
    border-style: solid;
    border-width: 10px;
    
}
.square{
    list-style-image: url(/src/assets/images/bullet_square.gif);
}
.anchor
{
    color:#00345B !important;
}
   