


#EnergyIndex {
    font-size: 11px;
    font-family: Verdana, sans-serif;
}

.EnergyIndex_a {
    font-weight: normal;
    color: #00345B;
    font-size: 11px;
    text-decoration: underline;
}

.EnergyIndex_a:link {
    font-weight: normal;
    color: #00345B;
    font-size: 11px;
    text-decoration: underline;
}

.EnergyIndex_a:visited {
    font-weight: normal;
    color: #00345B;
    font-size: 11px;
    text-decoration: underline;
}

.EnergyIndex_p {
    padding-bottom: 5px;
    padding-top: 5px;
    margin-top: 0;
    margin-bottom:0;
}